import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import {
  createBinancePay,
  getCanClaimOffer,
  getHotOffer,
  getHotToGetOffer,
  getOfferClaimUrl,
  getPowOfferClaim,
} from '@api';

import { AUTH_USER_QUERY_KEY } from './keys';

export const hotOfferQueryKeys = {
  hotOffer: (offerId: string) => [AUTH_USER_QUERY_KEY, 'hotOffer', offerId],
  powHotOffer: (offerId: string) => [AUTH_USER_QUERY_KEY, 'powHotOffer', offerId],
  claimUrl: (offerId: string) => [AUTH_USER_QUERY_KEY, 'claimUrl', offerId],
  canClaimOffer: (offerId: string) => [AUTH_USER_QUERY_KEY, 'canClaimOffer', offerId],
};

export const useHotOfferQuery = (
  offerId: string,
  options?: Pick<
    UndefinedInitialDataOptions<Awaited<ReturnType<typeof getHotOffer>>, unknown, unknown, any>,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: hotOfferQueryKeys.hotOffer(offerId),
    queryFn: () => getHotOffer(offerId),
    ...options,
  });
};

export const useHotToGetOfferQuery = (
  offerId: string,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getHotToGetOffer>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'staleTime' | 'gcTime'
  >,
) => {
  return useQuery({
    queryKey: hotOfferQueryKeys.powHotOffer(offerId),
    queryFn: () => getHotToGetOffer({ searchParams: { offerId } }),
    ...options,
  });
};

export const prefetchHotOfferQuery = async (
  clientQuery: QueryClient,
  offerId: string,
  options?: Parameters<typeof getHotOffer>[1],
) => {
  const queryKey = hotOfferQueryKeys.hotOffer(offerId);

  await clientQuery.prefetchQuery({ queryKey, queryFn: () => getHotOffer(offerId, options) });

  return clientQuery.getQueryData<ReturnType<typeof getHotOffer>>(queryKey);
};

export const useCanClaimOfferQuery = (
  offerId: string,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getCanClaimOffer>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: hotOfferQueryKeys.canClaimOffer(offerId),
    queryFn: () => getCanClaimOffer(offerId),
    staleTime: 0,
    ...options,
  });
};

export const useClaimOfferUrlQuery = (
  offerId: string,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getOfferClaimUrl>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: hotOfferQueryKeys.claimUrl(offerId),
    queryFn: () => getOfferClaimUrl(offerId),
    staleTime: 0,
    ...options,
  });
};

export const useClaimPowOfferQuery = (
  offerId: string,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getPowOfferClaim>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: hotOfferQueryKeys.powHotOffer(offerId),
    queryFn: () => getPowOfferClaim({ json: { offerId } }),
    staleTime: 0,
    ...options,
  });
};

export const useCreateBinancePayMutation = () => {
  return useMutation({
    mutationFn: (offerId: string) => createBinancePay({ searchParams: { offerId } }),
  });
};
